<template>
  <v-container>
    <v-card width="500" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1">Login</h1>
      </v-card-title>
      <v-card-text>
        <v-form @submit="onSubmit">
          <v-text-field
            label="Username"
            prepend-icon="mdi-account-circle"
            v-model="form.username"
            required
          />

          <v-text-field
            label="Password"
            :type="showPasswd ? 'text' : 'password'"
            prepend-icon="mdi-lock"
            :append-icon="showPasswd ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showPasswd = !showPasswd"
            v-model="form.passwd"
            required
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="success">Register</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" type="submit" @click="onSubmit">Login</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" top color="error">
      <p>Invalid login details. Try again.</p>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "Login",

  data: () => ({
    showPasswd: false,
    form: { username: "", passwd: "" },
    snackbar: false
  }),
  methods: {
    onSubmit() {
      if (this.form.username === "oodua" && this.form.passwd === "pass123") {
        const redirectPath = this.$route.query.redirect || "/";
        this.$router.push(redirectPath);
      } else {
        this.snackbar = true;
        return false;
      }
    }
  }
};
</script>
